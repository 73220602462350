import type { PropType, VNode } from 'vue';

interface SummaryList {
  summary: string;
  items: { label: string; target?: string; url: string }[];
}

export default defineNuxtComponent({
  name: 'ContentSummaryList',
  props: {
    summaryList: { default: undefined, type: Object as PropType<SummaryList> },
  },
  async setup(props) {
    const list: SummaryList = {
      summary: props.summaryList?.summary ?? '',
      items: await Promise.all(
        props.summaryList?.items.map(async (item) => ({
          label: item.label,
          target: item.target,
          url: await getFirebaseStorageUrl(item.url),
        })) ?? []
      ),
    };
    return { list };
  },
  render(): VNode | null {
    if (!this.summaryList) return null;
    return (
      <details>
        <summary class="font-bold">{this.summaryList.summary}</summary>
        <ul>
          {this.list.items.map((item) => (
            <li>
              <a href={item.url} target={item.target} v-html={item.label} />
            </li>
          ))}
        </ul>
      </details>
    );
  },
});
