import payload_plugin_m6tKastKJw from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.4.0_encoding@0.1.13__firebase-_n6tamkvgc5zrq2okhbmmh4hk5a/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_wmme4aNzAc from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._ydmfjhcxqqhxl6kkxqpdbfxksa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_SAnizpVfKn from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._ydmfjhcxqqhxl6kkxqpdbfxksa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_LM7psoKYJE from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._ydmfjhcxqqhxl6kkxqpdbfxksa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import naive_ui_tKb6Ec2ET9 from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/plugins/naive-ui.ts";
import payload_client_w0aW1CTEkp from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._ydmfjhcxqqhxl6kkxqpdbfxksa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_YVFBpbwikT from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._ydmfjhcxqqhxl6kkxqpdbfxksa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jGxMSrdaWE from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._ydmfjhcxqqhxl6kkxqpdbfxksa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sUk4u2RsmJ from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._ydmfjhcxqqhxl6kkxqpdbfxksa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/niko/Developer/FunAcademy/fun-academy-campus/.nuxt/components.plugin.mjs";
import prefetch_client_KRmpMf5fqM from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@9.10.0_jiti@1.21._ydmfjhcxqqhxl6kkxqpdbfxksa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_VsbBggwXh6 from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.4.0_encoding@0.1.13__firebase-_n6tamkvgc5zrq2okhbmmh4hk5a/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/Users/niko/Developer/FunAcademy/fun-academy-campus/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/Users/niko/Developer/FunAcademy/fun-academy-campus/.nuxt/vuefire-plugin.mjs";
import plugin_kMNoDWWGvR from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/nuxtjs-naive-ui@1.0.2_magicast@0.3.5_rollup@4.21.2_vue@3.4.35_typescript@5.5.4__webpack-sources@3.2.3/node_modules/nuxtjs-naive-ui/dist/runtime/plugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/Users/niko/Developer/FunAcademy/fun-academy-campus/.nuxt/pwa-icons-plugin.ts";
import pwa_client_zexPdPL8hj from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22.5.4_terser@5.31._npkgpwq5pc62qfto7jmnrxe3c4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import unocss_MzCDxu9LMj from "/Users/niko/Developer/FunAcademy/fun-academy-campus/.nuxt/unocss.mjs";
import switch_locale_path_ssr_t4WQtFpwoH from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.5_rollup@4.21.2_vue@3.4.35_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ZeJtFUG7VZ from "/Users/niko/Developer/FunAcademy/fun-academy-campus/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.5_rollup@4.21.2_vue@3.4.35_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _logger_vGcu5iraAU from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/plugins/_logger.ts";
import analytics_client_ClOs2HRIGm from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/plugins/analytics.client.ts";
import vue_query_JC9gnf7CA1 from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/plugins/vue-query.ts";
export default [
  payload_plugin_m6tKastKJw,
  revive_payload_client_wmme4aNzAc,
  unhead_SAnizpVfKn,
  router_LM7psoKYJE,
  naive_ui_tKb6Ec2ET9,
  payload_client_w0aW1CTEkp,
  navigation_repaint_client_YVFBpbwikT,
  check_outdated_build_client_jGxMSrdaWE,
  chunk_reload_client_sUk4u2RsmJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_KRmpMf5fqM,
  plugin_client_VsbBggwXh6,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_kMNoDWWGvR,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_zexPdPL8hj,
  unocss_MzCDxu9LMj,
  switch_locale_path_ssr_t4WQtFpwoH,
  i18n_ZeJtFUG7VZ,
  _logger_vGcu5iraAU,
  analytics_client_ClOs2HRIGm,
  vue_query_JC9gnf7CA1
]