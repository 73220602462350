import type { VNode } from 'vue';

export default defineNuxtComponent({
  name: 'SideImage',
  async setup() {
    const { currentCourse, currentModule, currentTask } = useContentStore();
    const route = useRoute();
    const alt = computed<null | string | undefined>(() =>
      currentTask.value && isTask(String(route.params.id))
        ? currentTask.value?.sideImage?.alt
        : currentModule.value && isModule(String(route.params.id))
          ? currentModule.value?.sideImage?.alt
          : currentCourse.value && isCourse(String(route.params.id))
            ? currentCourse.value?.sideImage?.alt
            : null
    );
    const src =
      currentTask.value && isTask(String(route.params.id))
        ? await getFirebaseStorageUrl(currentTask.value?.sideImage?.src)
        : currentModule.value && isModule(String(route.params.id))
          ? await getFirebaseStorageUrl(currentModule.value?.sideImage?.src)
          : currentCourse.value && isCourse(String(route.params.id))
            ? await getFirebaseStorageUrl(currentCourse.value?.sideImage?.src)
            : null;
    return { alt, src };
  },
  render(): VNode | null {
    if (!this.src) return null;
    return (
      <figure class="leading-none	mb-4 ml-auto mr-0 mt-0 border-1 border-gray-300 border-solid p-2 rounded-lg text-center transition-all xs:float-right xs:ml-4 xs:w-34 sm:w-46 md:w-52">
        <img
          alt={this.alt ?? ''}
          class="m-auto h-auto object-cover rounded-lg w-full"
          src={this.src}
        />
      </figure>
    );
  },
});
